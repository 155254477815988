import axios, { AxiosError } from 'axios'

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
}

type StartOtpResult = 'created' | 'not_found' | 'unauthorized' | 'conflict'

export const startOtp = async (to: string): Promise<StartOtpResult> => {
  try {
    await axios.post('https://api.oleen.team/auth/otp', { to }, CONFIG)
    return 'created'
  } catch (e) {
    const error = e as AxiosError
    if (error.response?.status === 404) {
      return 'not_found'
    }
    if (error.response?.status === 409) {
      return 'conflict'
    }
    return 'unauthorized'
  }
}

export const checkOtp = async (to: string, code: string) => {
  try {
    const response = await axios.post('https://api.oleen.team/auth/otp_check', { to, code }, CONFIG)
    return response.data as { token: string }
  } catch (e) {
    const error = e as AxiosError
    if (error.response?.status === 404) {
      return 'not_found'
    } else if (error.response?.status === 409) {
      return 'conflict'
    } else if (error.response?.status === 410) {
      return 'gone'
    } else if (error.response?.status === 401) {
      return 'unauthorized'
    }
  }
}
